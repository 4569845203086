input:not([type="checkbox"]):not([type="submit"]),
select,
textarea {
  background-color: theme("colors.background.light");
  border: 1px solid theme("colors.border");
  border-radius: 5px;
  color: theme("colors.text.default");
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 6px 12px;
  width: 100%;

  &:disabled {
    background-color: theme("colors.gray");
    opacity: 1;
  }
}
input[type="checkbox"], input[type="file"] {
  cursor: pointer;
}

label a { text-decoration: underline; }

::-webkit-file-upload-button { cursor: inherit; }

:global {
  .bg-check, .checked\:bg-check {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  }
  // NOTE: Unused
  label:has(.tooltip) {
    padding-right: 5px;
    position: relative;

    &:after {
      background-color: theme("colors.background.blue");
      content: "";
      display: block;
      height: 8px;
      left: 100%;
      position: absolute;
      top: calc(50% - 4px);
      transform: rotate(45deg);
      width: 8px;
    }
    &:active, &:hover {
      .tooltip {
        background-color: theme("colors.background.blue");
        border-radius: 5px;
        color: theme("colors.text.light");
        display: block;
        left: calc(100% + 4px);
        max-width: 400px;
        padding: 5px;
        position: absolute;
        top: -7.5px;
        width: max-content;
        z-index: 10;
      }
    }
  }
  .tooltip {
    display: none;
  }
}
