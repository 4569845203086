@use "sass:math";

:global {
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  @tailwind screens;
}

@import "./style/variables.scss";
@import "./style/mixins.scss";
@import "./style/default.scss";
@import "./style/action-text.scss";

// TODO: Look into if this was needed
// @include customAttribute($prop: "border", $value: inherit);
